import { useCallback, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";

import jwt from "jsonwebtoken";
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    "Content-Type": "application/json",
    "api-key": process.env.REACT_APP_SERVER_API_KEY,
  },
});

const useAxiosInterceptor = () => {
  const cookies = new Cookies();
  const [authToken, setAuthToken] = useState("");

  // Auth Token Cookies Management
  const getAuthTokenCookie = useCallback(
    () => cookies.get("secure-access-token"),
    []
  );
  const setAuthTokenCookies = useCallback((authToken: string) => {
    cookies.set("secure-access-token", authToken, {
      path: "/",
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
    });
  }, []);
  const removeAuthTokenCookies = useCallback(
    () => cookies.remove("secure-access-token"),
    []
  );

  // Role Token Cookies Management
  const getRoleTokenCookie = useCallback(
    () => cookies.get("secure-role-token"),
    []
  );
  const setRoleTokenCookies = useCallback(
    ({ roleToken, userId }: { roleToken: string; userId?: string }) => {
      if (!roleToken) return;
      try {
        if (!process.env.REACT_APP_ROLE_TOKEN_SECRET) {
          throw new Error(
            "Environment variable REACT_APP_ROLE_TOKEN_SECRET is not set."
          );
        }
  
        let encodedRoleToken;
        try {
          encodedRoleToken = jwt.sign(
            { role: roleToken, userId: userId },
            process.env.REACT_APP_ROLE_TOKEN_SECRET,
            { expiresIn: "24h" }
          );
        } catch (error: any) {
          throw new Error(error);
        }
  
        if (encodedRoleToken)
          return cookies.set("secure-role-token", encodedRoleToken, {
            path: "/",
            expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
          });
        return;
      } catch (error) {
        console.log("Error setting role token:", error);
        throw new Error("Error setting role token.");
      }
    },
    []
  );  
  const removeRoleTokenCookies = useCallback(
    () => cookies.remove("secure-role-token"),
    []
  );

  // User ID Cookies Management
  const getUserIdCookies = useCallback(() => cookies.get("userId"), []);
  const setUserIdCookies = useCallback((userId: string) => {
    cookies.set("userId", userId, {
      path: "/",
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
    });
  }, []);
  const removeAllCookies = useCallback(() => {
    cookies.remove("secure-access-token", { path: "/" });
    cookies.remove("secure-role-token", { path: "/" });
    cookies.remove("userId", { path: "/" });
  }, []);
  useEffect(() => {
    if (authToken) {
      setAuthTokenCookies(authToken);
    }
  }, [authToken]);

  // Token Refresh Logic
  useEffect(() => {
    const tokenFromCookies = getAuthTokenCookie();
    axiosInstance.interceptors.request.use(
      async (config) => {
        const token = tokenFromCookies || authToken;
        const roleToken = getRoleTokenCookie();
        const userId = getUserIdCookies();
        if (token) config.headers["Authorization"] = `Bearer ${token}`;
        if (roleToken) config.headers["x-role-token"] = roleToken;
        if (userId) config.headers["userId"] = userId;
        return config;
      },
      (error) => Promise.reject(error)
    );
  }, [
    authToken,
    setAuthTokenCookies,
    getAuthTokenCookie,
    getRoleTokenCookie,
    getUserIdCookies,
  ]);

  return {
    authToken,
    setAuthToken,
    getAuthTokenCookie,
    setAuthTokenCookies,
    removeAuthTokenCookies,
    getRoleTokenCookie,
    setRoleTokenCookies,
    removeRoleTokenCookies,
    removeAllCookies,
    setUserIdCookies,
    getUserIdCookies,
  };
};

export default useAxiosInterceptor;
