// import useLocalStorage from "../hooks/useLocalStorage";

import { signOut } from "firebase/auth";
import { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../firebase/firebase";
import { RedirectPaths, UserRoles } from "../utils/const";
import useAxiosInterceptor, {
  axiosInstance,
} from "../hooks/useAxiosInterceptor";
import { useQueryHook } from "../hooks/useQueyHook";
import { getUserDetailsById } from "../helpers/api";
import { useLocalStorage } from "usehooks-ts";

export interface UserData {
  address?: any;
  contactDetails?: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  createdAt: string;
  dateOfBirth: string | null;
  firebaseIds: { identifier: string /* Add other properties as needed */ }[];
  gender: string;
  iAcceptTermsAndPolicyOfConfidentiality: boolean;
  iAcceptTheCommitmentLetter: boolean;
  iAcceptToBeContactedForParticipatingInAStudy: boolean;
  iAgreeToReceiveInformationAndUpdates: boolean;
  parentHasChildren: boolean;
  status: string;
  userId: string;
  userRole: string;
  _id: string;
  createdBy: string;
  // Add other properties as needed
}

const current = new Date();
const AuthContext = createContext({});
// useAuthContext custom hook gives us the useContext hook with AuthContext provided to it.
export const useAuthContext = () => useContext<any>(AuthContext);

// AuthContext component that we will use as wrapper to those components that should consume the User Context API
const AuthContextProvider = ({ children }: any) => {
  const { getUserIdCookies } = useAxiosInterceptor();
  // const userId = getUserIdCookies();
  const nextYear = new Date();
  nextYear.setFullYear(current.getFullYear() + 1);

  const [userData, setUserData] = useState<any>({});
  const { removeAllCookies } = useAxiosInterceptor();
  // useQueryHook({
  //   queryKey: ["current-user", userId],
  //   queryFn: getUserDetailsById,
  //   queryOptions: {
  //     onSuccess: (data: any) => {
  //       console.log("current user data", data);
  //     },
  //     onError: (error: any) => {
  //       removeAllCookies();
  //       localStorage.removeItem("user");
  //       window.location.reload();
  //     },
  //   },
  // });

  const logoutUser = async (user: any, history: any) => {
    await signOut(auth).then(() => {
      removeAllCookies();
      localStorage.removeItem("user");
      sessionStorage.clear();
      switch (user?.userRole) {
        case UserRoles.TUTOR:
          history?.push(RedirectPaths.TUTOR_LOGIN);
          break;

        case UserRoles.PARENT:
          history?.push(RedirectPaths.PARENT_LOGIN);
          break;

        case UserRoles.STUDENT:
          history?.push(RedirectPaths.STUDENT_LOGIN);
          break;

        case UserRoles.ADMIN:
          history?.push(RedirectPaths.IS_LOGIN);
          break;

        case UserRoles.MANAGER:
          history?.push(RedirectPaths.IS_LOGIN);
          break;

        case UserRoles.COORDINATOR:
          history?.push(RedirectPaths.IS_LOGIN);
          break;

        case UserRoles.VSC:
          history?.push(RedirectPaths.IS_LOGIN);
          break;
      }
    });
  };
  const value = {
    logoutUser,
    setUserData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
