import {
  ReportsStatus,
  ReportsTypes,
  StatusOfEstablishment,
} from "../utils/const";

export const NumberOfStudentsList = Array.from(
  { length: 20 },
  (_, index) => index + 1
);

export const StrongAreas = (intl: any) => {
  return [
    { name: intl.formatMessage({ id: "learning" }), value: "learning" },
    { name: intl.formatMessage({ id: "motivation" }), value: "motivation" },
    { name: intl.formatMessage({ id: "organization" }), value: "organization" },
    {
      name: intl.formatMessage({ id: "self-confidence" }),
      value: "self-confidence",
    },
    { name: intl.formatMessage({ id: "self-esteem" }), value: "self-esteem" },
    { name: intl.formatMessage({ id: "self-control" }), value: "self-control" },
    {
      name: intl.formatMessage({ id: "self-discipline" }),
      value: "self-discipline",
    },
  ];
};

export const LevelOfStudents = (intl: any) => {
  return [
    { value: "b1", name: intl.formatMessage({ id: "level" }) + " B1" },
    { value: "b2", name: intl.formatMessage({ id: "level" }) + " B2" },
    { value: "c1", name: intl.formatMessage({ id: "level" }) + " B3" },
  ];
};

export const LevelOfStudentsSupporting = [
  { levelCode: "1", levelName: "1 Student" },
  { levelCode: "2", levelName: "2 Students" },
  { levelCode: "3", levelName: "3 Students" },
  { levelCode: "4", levelName: "4 Students" },
  { levelCode: "5", levelName: "5 Students" },
  { levelCode: "6", levelName: "6 Students" },
  { levelCode: "7", levelName: "7 Students" },
  { levelCode: "8", levelName: "8 Students" },
  { levelCode: "9", levelName: "9 Students" },
  { levelCode: "10", levelName: "10 Students" },
];

export const TutoringCoursesList = (intl: any) => {
  return [
    {
      name: intl.formatMessage({ id: "sciences-et-technologies" }),
      value: "sciences-et-technologies",
    },
    {
      name: intl.formatMessage({ id: "lettres-les-langues-lart" }),
      value: "lettres-les-langues-lart",
    },
    {
      name: intl.formatMessage({ id: "sciences-humaines-et-sociales" }),
      value: "sciences-humaines-et-sociales",
    },
    {
      name: intl.formatMessage({ id: "droit-et-sciences-politiques" }),
      value: "droit-et-sciences-politiques",
    },
    {
      name: intl.formatMessage({ id: "economie-et-gestion" }),
      value: "economie-et-gestion",
    },
    { name: intl.formatMessage({ id: "sante" }), value: "sante" },
    { name: intl.formatMessage({ id: "sport" }), value: "sport" },
    { name: intl.formatMessage({ id: "staps" }), value: "staps" },
    {
      name: intl.formatMessage({ id: "sciences-de-leducation" }),
      value: "sciences-de-leducation",
    },
    {
      name: intl.formatMessage({ id: "sciences-de-la-vie-et-de-la-terre" }),
      value: "sciences-de-la-vie-et-de-la-terre",
    },
    {
      name: intl.formatMessage({ id: "sciences-pour-la-sante" }),
      value: "sciences-pour-la-sante",
    },
    {
      name: intl.formatMessage({ id: "sciences-pour-lingenieur" }),
      value: "sciences-pour-lingenieur",
    },
    {
      name: intl.formatMessage({ id: "sciences-du-langage" }),
      value: "sciences-du-langage",
    },
    { name: intl.formatMessage({ id: "sociologie" }), value: "sociologie" },
    {
      name: intl.formatMessage({ id: "communication-marketing" }),
      value: "communication-marketing",
    },
    { name: intl.formatMessage({ id: "commerce" }), value: "commerce" },
    {
      name: intl.formatMessage({ id: "eco-gestion-administration" }),
      value: "eco-gestion-administration",
    },
    {
      name: intl.formatMessage({ id: "mathematique-informatique" }),
      value: "mathematique-informatique",
    },
    {
      name: intl.formatMessage({ id: "histoire-geographie" }),
      value: "histoire-geographie",
    },
    { name: intl.formatMessage({ id: "psychologie" }), value: "psychologie" },
    { name: intl.formatMessage({ id: "journalisme" }), value: "journalisme" },
    { name: intl.formatMessage({ id: "autres" }), value: "autres" },
  ];
};

export const VscStudyLevelList = (intl: any) => {
  return [
    {
      name: intl.formatMessage({ id: "brevet-des-colleges" }),
      value: "brevet-des-colleges",
    },
    {
      name: intl.formatMessage({ id: "seconde-professionnelle" }),
      value: "seconde-professionnelle",
    },
    {
      name: intl.formatMessage({ id: "premiere-professionnelle" }),
      value: "premiere-professionnelle",
    },
    {
      name: intl.formatMessage({ id: "terminale-professionnelle" }),
      value: "terminale-professionnelle",
    },
    {
      name: intl.formatMessage({ id: "seconde-generale-et-technologique" }),
      value: "seconde-generale-et-technologique",
    },
    {
      name: intl.formatMessage({ id: "premiere-generale-et-technologique" }),
      value: "premiere-generale-et-technologique",
    },
    {
      name: intl.formatMessage({ id: "terminale-generale-et-technologique" }),
      value: "terminale-generale-et-technologique",
    },
    { name: intl.formatMessage({ id: "bac-1" }), value: "bac-1" },
    { name: intl.formatMessage({ id: "bac-2" }), value: "bac-2" },
    { name: intl.formatMessage({ id: "bac-3" }), value: "bac-3" },
    { name: intl.formatMessage({ id: "bac-4" }), value: "bac-4" },
    { name: intl.formatMessage({ id: "bac-5" }), value: "bac-5" },
  ];
};

export const SubjectsList = (intl: any) => {
  return [
    {
      value: "scientific-and-first",
      name: intl.formatMessage({ id: "scientific-and-first" }),
    },
    {
      value: "literary-and-first",
      name: intl.formatMessage({ id: "literary-and-first" }),
    },
    {
      value: "both-subjects",
      name: intl.formatMessage({ id: "both-subjects" }),
    },
  ];
};

export const GenderList = (intl: any, mode = "normal") => {
  if (mode === "child")
    return [
      { value: "male", name: intl.formatMessage({ id: "boy" }) },
      { value: "female", name: intl.formatMessage({ id: "girl" }) },
    ];
  else
    return [
      { value: "male", name: intl.formatMessage({ id: "male" }) },
      { value: "female", name: intl.formatMessage({ id: "female" }) },
    ];
};

export const TutoringTypesList = (intl: any) => {
  return [
    { value: "private", name: intl.formatMessage({ id: "private" }) },
    { value: "group", name: intl.formatMessage({ id: "group" }) },
  ];
};

export const MatchingTypesFilterStatus = (intl: any) => {
  return [
    {
      value: "unmatched",
      name: intl.formatMessage({ id: "unmatched-status" }),
    },
    {
      value: "private-session",
      name: intl.formatMessage({ id: "matching-confirmed-status" }),
    },
    // {
    //   value: "all-others",
    //   name: intl.formatMessage({ id: "other-matchings-status" }),
    // },
  ];
};

export const TruthAndFalseList = (intl: any) => {
  return [
    { value: true, name: intl.formatMessage({ id: "yes" }) },
    { value: false, name: intl.formatMessage({ id: "no" }) },
  ];
};

export const SessionsStatusList = (intl: any) => {
  return [
    {
      value: "canceled",
      name: intl.formatMessage({ id: "session-0-abandoned" }),
    },
    {
      value: "session-0-on-hold",
      name: intl.formatMessage({ id: "session-0-on-hold" }),
    },
    {
      value: "session-0-to-be-scheduled",
      name: intl.formatMessage({ id: "session-0-to-be-scheduled" }),
    },
    /* {
      value: "session-0-suggested",
      name: intl.formatMessage({ id: "session-0-suggested" }),
    },
    {
      value: "session-0-called-again",
      name: intl.formatMessage({ id: "session-0-called-again" }),
    },
    {
      value: "session-0-confirmed",
      name: intl.formatMessage({ id: "session-0-confirmed" }),
    },
    {
      value: "session-0-pair-ok",
      name: intl.formatMessage({ id: "session-0-pair-ok" }),
    },
    {
      value: "session-0-finish-stop",
      name: intl.formatMessage({ id: "session-0-finish-stop" }),
    },
    { value: "canceled", name: intl.formatMessage({ id: "canceled" }) },
    { value: "failed", name: intl.formatMessage({ id: "failed" }) },
    { value: "scheduled", name: intl.formatMessage({ id: "scheduled" }) },
    { value: "confirmed", name: intl.formatMessage({ id: "confirmed" }) }, */
  ];
};

export const MatchingProgramsList = (intl: any): any => {
  return [
    {
      programId: "az-rem-4ca0f8d",
      programName: intl.formatMessage({ id: "face-to-face" }),
      valueUnformated: "HomeClasse",
    },

    {
      programId: "az-zup-6g98343",
      programName: "ZUPdeFOOT",
      valueUnformated: "ZUPdeFOOT",
    },
    {
      programId: "az-per-2ca0z9s",
      programName: "ClassHome",
      valueUnformated: "ClassHome",
    },
  ];
};
export const ProgramsList = (intl: any): any => {
  return [
    {
      programId: "az-rem-4ca0f8d",
      programName: intl.formatMessage({ id: "face-to-face" }),
      valueUnformated: "HomeClasse",
    },
    {
      programId: "az-fac-5f87232",
      programName: intl.formatMessage({ id: "remote" }),
      valueUnformated: "Tutorat solidaire",
    },
    {
      programId: "az-zup-6g98343",
      programName: "ZUPdeFOOT",
      valueUnformated: "ZUPdeFOOT",
    },
    {
      programId: "az-per-2ca0z9s",
      programName: "ClassHome",
      valueUnformated: "ClassHome",
    },
  ];
};

export const CategoryList = (intl: any) => {
  return [
    {
      value: "elementary-school",
      name: intl.formatMessage({ id: "category-elementary-school" }),
    },
    {
      value: "middle-school",
      name: intl.formatMessage({ id: "category-middle-school" }),
    },
    {
      value: "high-school",
      name: intl.formatMessage({ id: "category-high-school" }),
    },
    { value: "other", name: intl.formatMessage({ id: "category-other" }) },
  ];
};

export const SchoolsCategoryValues = [
  "elementary-school",
  "middle-school",
  "high-school",
  "other",
];

export const TutorFilterCategoryValues = [
  "elementary-school",
  "middle-school",
  "high-school",
  "company",
  "other",
];

export const CategoryOtherList = (intl: any) => {
  return [
    { value: "company", name: intl.formatMessage({ id: "category-company" }) },
    {
      value: "association",
      name: intl.formatMessage({ id: "category-association" }),
    },
    { value: "other", name: intl.formatMessage({ id: "category-other" }) },
  ];
};

export const SocioProfessionalCategories = (intl: any) => {
  return [
    {
      value: "seniorExecutivesAndProfessionals",
      name: intl.formatMessage({ id: "seniorExecutivesAndProfessionals" }),
    },
    {
      value: "intermediateProfessions",
      name: intl.formatMessage({ id: "intermediateProfessions" }),
    },
    {
      value: "unemployed",
      name: intl.formatMessage({ id: "unemployed" }),
    },
    {
      value: "worker",
      name: intl.formatMessage({ id: "worker" }),
    },
    {
      value: "artisansTradesmenBusinessOwners",
      name: intl.formatMessage({ id: "artisansTradesmenBusinessOwners" }),
    },
    {
      value: "farmer",
      name: intl.formatMessage({ id: "farmer" }),
    },
    {
      value: "employees",
      name: intl.formatMessage({ id: "employees" }),
    },
    {
      value: "retirees",
      name: intl.formatMessage({ id: "retirees" }),
    },
  ];
};

export const TutorActivityTypes = (intl: any) => {
  return [
    {
      value: "searchin-for-a-job",
      name: intl.formatMessage({ id: "unemployed" }),
    },
    {
      value: "student",
      name: intl.formatMessage({ id: "high-school-student" }),
    },
    {
      value: "employee",
      name: intl.formatMessage({ id: "employees" }),
    },
    {
      value: "retirees",
      name: intl.formatMessage({ id: "retirees" }),
    },
  ];
};

export const StructureTypes = (intl: any) => {
  return [
    {
      label: intl.formatMessage({ id: "higher-school-list" }),
      options: [
        {
          value: 4,
          name: "business-school",
          label: intl.formatMessage({ id: "business-school" }),
        },
        {
          value: 5,
          name: "engineering-school",
          label: intl.formatMessage({ id: "engineering-school" }),
        },
        { value: 6, name: "iut", label: intl.formatMessage({ id: "iut" }) },
        {
          value: 7,
          name: "university",
          label: intl.formatMessage({ id: "education_university" }),
        },
        {
          value: 8,
          name: "ufr",
          label: intl.formatMessage({ id: "ufr" }),
        },
        {
          value: 12,
          name: "École",
          label: intl.formatMessage({ id: "school" }),
        },
        {
          value: 13,
          name: "Grand établissement",
          label: intl.formatMessage({ id: "great-establishment" }),
        },
        {
          value: 14,
          name: "ecole superieure",
          label: intl.formatMessage({ id: "superior-school" }),
        },
      ],
    },
    {
      label: intl.formatMessage({ id: "other" }),
      options: [
        {
          value: 9,
          name: "company",
          label: intl.formatMessage({ id: "category-company" }),
        },
        {
          value: 10,
          name: "association",
          label: intl.formatMessage({ id: "category-association" }),
        },
        {
          value: 11,
          name: "other",
          label: intl.formatMessage({ id: "other" }),
        },
        {
          value: 15,
          name: "autre établissement",
          label: intl.formatMessage({ id: "other-establishment" }),
        },
      ],
    },
  ];
};

export const StructureTypeNames = [
  "ecole superieure",
  "École",
  "university",
  "Grand établissement",
  "autre établissement",
];

export const EstablishmentTypes = (intl: any) => {
  return [
    {
      label: intl.formatMessage({ id: "school-list" }),
      options: [
        {
          value: 1,
          name: "elementary-school",
          label: intl.formatMessage({ id: "category-elementary-school" }),
        },
        {
          value: 2,
          name: "middle-school",
          label: intl.formatMessage({ id: "category-middle-school" }),
        },
        {
          value: 3,
          name: "high-school",
          label: intl.formatMessage({ id: "category-high-school" }),
        }
      ],
    },
    {
      label: intl.formatMessage({ id: "higher-school-list" }),
      options: [
        {
          value: 4,
          name: "business-school",
          label: intl.formatMessage({ id: "business-school" }),
        },
        {
          value: 5,
          name: "engineering-school",
          label: intl.formatMessage({ id: "engineering-school" }),
        },
        { value: 6, name: "iut", label: intl.formatMessage({ id: "iut" }) },
        {
          value: 7,
          name: "university",
          label: intl.formatMessage({ id: "education_university" }),
        },
        {
          value: 8,
          name: "ufr",
          label: intl.formatMessage({ id: "ufr" }),
        },
        {
          value: 12,
          name: "École",
          label: intl.formatMessage({ id: "school" }),
        },
        {
          value: 13,
          name: "Grand établissement",
          label: intl.formatMessage({ id: "great-establishment" }),
        },
        {
          value: 14,
          name: "ecole superieure",
          label: intl.formatMessage({ id: "superior-school" }),
        },
      ],
    },
    {
      label: intl.formatMessage({ id: "other" }),
      options: [
        {
          value: 9,
          name: "company",
          label: intl.formatMessage({ id: "category-company" }),
        },
        {
          value: 10,
          name: "association",
          label: intl.formatMessage({ id: "category-association" }),
        },
        {
          value: 11,
          name: "other",
          label: intl.formatMessage({ id: "other" }),
        },
        {
          value: 15,
          name: "autre établissement",
          label: intl.formatMessage({ id: "other-establishment" }),
        },
      ],
    }
  ];
};


export const MeasuresOfSuccess = (intl: any) => {
  return [
    {
      value: "bad",
      name: intl.formatMessage({ id: "bad" }),
      valueUnformated: "Bad",
    },
    {
      value: "average",
      name: intl.formatMessage({ id: "average" }),
      valueUnformated: "Average",
    },
    {
      value: "good",
      name: intl.formatMessage({ id: "good" }),
      valueUnformated: "Good",
    },
    {
      value: "very–good",
      name: intl.formatMessage({ id: "very–good" }),
      valueUnformated: "Very good",
    },
  ];
};

export const EstablishmentPriority = (intl: any) => {
  return [
    {
      value: "non-priority",
      name: intl.formatMessage({ id: "priority-non-priority" }),
      valueUnformated: "Non-priority",
    },
    {
      value: "college-cappe",
      name: intl.formatMessage({ id: "priority-college-cappe" }),
      valueUnformated: "college CAPPE",
    },
    {
      value: "city-policy",
      name: intl.formatMessage({ id: "priority-city-policy" }),
      valueUnformated: "City policy",
    },
    {
      value: "rep",
      name: intl.formatMessage({ id: "priority-rep" }),
      valueUnformated: "REP",
    },
    {
      value: "rep-plus",
      name: intl.formatMessage({ id: "priority-rep-plus" }),
      valueUnformated: "REP+",
    },
    {
      value: "other",
      name: intl.formatMessage({ id: "other" }),
      valueUnformated: "Other",
    },
  ];
};

export const EducationType = (intl: any) => {
  return [
    {
      value: "business-school",
      name: intl.formatMessage({ id: "business-school" }),
    },
    {
      value: "engineering-school",
      name: intl.formatMessage({ id: "engineering-school" }),
    },
    { value: "iut", name: intl.formatMessage({ id: "iut" }) },
    {
      value: "university",
      name: intl.formatMessage({ id: "education_university" }),
    },
    { value: "ufr", name: intl.formatMessage({ id: "ufr" }) },
    { value: "other", name: intl.formatMessage({ id: "other" }) },
  ];
};

export const DaysOfTheWeek = (intl: any, weekendDays: boolean = true) => {
  const days = [
    { value: 1, name: intl.formatMessage({ id: "monday" }) },
    { value: 2, name: intl.formatMessage({ id: "tuesday" }) },
    { value: 3, name: intl.formatMessage({ id: "wednesday" }) },
    { value: 4, name: intl.formatMessage({ id: "thursday" }) },
    { value: 5, name: intl.formatMessage({ id: "friday" }) },
  ];

  if (weekendDays) {
    return [
      ...days,
      { value: 6, name: intl.formatMessage({ id: "saturday" }) },
      { value: 7, name: intl.formatMessage({ id: "sunday" }) },
    ];
  } else return days;
};

export const SessionTypes = (intl: any) => {
  return [
    { value: "session-0", name: intl.formatMessage({ id: "session-0" }) },
    {
      value: "group-session",
      name: intl.formatMessage({ id: "group-session" }),
    },
    {
      value: "private-session",
      name: intl.formatMessage({ id: "private-session" }),
    },
  ];
};

export const SessionRecurrenceType = (intl: any) => {
  return [
    { value: "one-time", name: intl.formatMessage({ id: "one-time" }) },
    { value: "weekly", name: intl.formatMessage({ id: "weekly" }) },
    { value: "bi-monthly", name: intl.formatMessage({ id: "bi-monthly" }) },
  ];
};

export const ReferenceList = (intl: any) => {
  return [
    { value: "university", name: intl.formatMessage({ id: "university" }) },
    { value: "high-school", name: intl.formatMessage({ id: "high-school" }) },
    { value: "company", name: intl.formatMessage({ id: "company" }) },
    {
      value: "external-event",
      name: intl.formatMessage({ id: "external-event" }),
    },
    { value: "website", name: intl.formatMessage({ id: "website" }) },
    {
      value: "national-service",
      name: intl.formatMessage({ id: "national-service" }),
    },
    {
      value: "social-network",
      name: intl.formatMessage({ id: "social-network" }),
    },
    {
      value: "internet-platform",
      name: intl.formatMessage({ id: "internet-platform" }),
    },
    {
      value: "previous-tutor",
      name: intl.formatMessage({ id: "previous-tutor" }),
    },
    {
      value: "word-of-mouth",
      name: intl.formatMessage({ id: "word-of-mouth" }),
    },
    { value: "other", name: intl.formatMessage({ id: "other" }) },
  ];
};

export const SubReferenceList = (intl: any) => {
  return [
    {
      value: "from-a-presentation",
      name: intl.formatMessage({ id: "from-a-presentation" }),
    },
    { value: "from-a-forum", name: intl.formatMessage({ id: "from-a-forum" }) },
    { value: "from-a-mail", name: intl.formatMessage({ id: "from-a-mail" }) },
    {
      value: "from-a-poster",
      name: intl.formatMessage({ id: "from-a-poster" }),
    },
    {
      value: "from-a-presentation-webinar",
      name: intl.formatMessage({ id: "from-a-presentation-webinar" }),
    },
    {
      value: "from-a-mail-or-intranet",
      name: intl.formatMessage({ id: "from-a-mail-or-intranet" }),
    },
    { value: "instagram", name: intl.formatMessage({ id: "instagram" }) },
    { value: "facebook", name: intl.formatMessage({ id: "facebook" }) },
    { value: "tiktok", name: intl.formatMessage({ id: "tiktok" }) },
    { value: "linkedin", name: intl.formatMessage({ id: "linkedin" }) },
    { value: "wenabi", name: intl.formatMessage({ id: "wenabi" }) },
    { value: "vendredi", name: intl.formatMessage({ id: "vendredi" }) },
    { value: "day-one", name: intl.formatMessage({ id: "day-one" }) },
    {
      value: "je-veux-aider",
      name: intl.formatMessage({ id: "je-veux-aider" }),
    },
    {
      value: "1jeune1mentor",
      name: intl.formatMessage({ id: "1jeune1mentor" }),
    },
    { value: "microdon", name: intl.formatMessage({ id: "microdon" }) },
    {
      value: "france-benevolat",
      name: intl.formatMessage({ id: "france-benevolat" }),
    },
    { value: "benevity", name: intl.formatMessage({ id: "benevity" }) },
    { value: "je-mengage", name: intl.formatMessage({ id: "je-mengage" }) },
    { value: "diffuz", name: intl.formatMessage({ id: "diffuz" }) },
    {
      value: "tous-benevoles",
      name: intl.formatMessage({ id: "tous-benevoles" }),
    },
    { value: "benevolt", name: intl.formatMessage({ id: "benevolt" }) },
  ];
};

export const UserStatusesList = (intl: any, config?: any): any => {
  let statuses = [
    { value: "active", name: intl.formatMessage({ id: "active" }) },
    { value: "deactivated", name: intl.formatMessage({ id: "deactivated" }) },
    { value: "on-hold", name: intl.formatMessage({ id: "on-hold" }) },
  ];

  if (config?.hasAll === true)
    statuses = [
      { value: "all", name: intl.formatMessage({ id: "all" }) },
      ...statuses,
    ];

  if (config?.isTutor === true)
    statuses = [
      ...statuses,
      {
        value: "commitment-end",
        name: intl.formatMessage({ id: "commitment-end" }),
      },
      {
        value: "preemptive-end",
        name: intl.formatMessage({ id: "preemptive-end" }),
      },
    ];

  return statuses;
};

export const TutorStatusesList = (intl: any) => {
  return [
    { value: "new", name: intl.formatMessage({ id: "new" }) },
    {
      value: "incomplete-relance-1",
      name: intl.formatMessage({ id: "incomplete-relance-1" }),
    },
    {
      value: "incomplete-relance-2",
      name: intl.formatMessage({ id: "incomplete-relance-2" }),
    },
    { value: "complete", name: intl.formatMessage({ id: "complete" }) },
    {
      value: "awaiting-student-1",
      name: intl.formatMessage({ id: "awaiting-student-1" }),
    },
    {
      value: "student-1-found",
      name: intl.formatMessage({ id: "student-1-found" }),
    },
    {
      value: "scheduled-session-0",
      name: intl.formatMessage({ id: "scheduled-session-0" }),
    },
    { value: "in-tutoring", name: intl.formatMessage({ id: "in-tutoring" }) },
    { value: "for-renewal", name: intl.formatMessage({ id: "for-renewal" }) },
    { value: "pause", name: intl.formatMessage({ id: "pause" }) },
    { value: "stop", name: intl.formatMessage({ id: "stop" }) },
    {
      value: "no-further-action",
      name: intl.formatMessage({ id: "no-further-action" }),
    },
  ];
};
export const TutorCreationEnum = (intl: any) => {
  return [
    { value: "admin", name: intl.formatMessage({ id: "administrator" }) },
    {
      value: "classic",
      name: intl.formatMessage({ id: "classic" }),
    },
  ];
};
export const onBoardingStepsList = (intl: any) => {
  return [
    { value: "1", name: intl.formatMessage({ id: "registration-form" }) },
    { value: "2", name: intl.formatMessage({ id: "video-viewing" }) },
    { value: "3", name: intl.formatMessage({ id: "quiz-completion" }) },
    {
      value: "5",
      name: intl.formatMessage({ id: "awaiting-validation-success" }),
    },
    {
      value: "6",
      name: intl.formatMessage({ id: "awaiting-validation-failed" }),
    },
  ];
};

export const StudentStatusesList = (intl: any) => {
  return [
    { value: "new", name: intl.formatMessage({ id: "new" }) },
    {
      value: "awaiting-tutor",
      name: intl.formatMessage({ id: "awaiting-tutor" }),
    },
    { value: "in-tutoring", name: intl.formatMessage({ id: "in-tutoring" }) },
    { value: "stop", name: intl.formatMessage({ id: "stop" }) },
  ];
};

export const StudentPartnerList = (intl: any) => {
  return [
    { value: "homeclasse-convention", name: "HomeClasse Convention" },
    { value: "local-de-blois", name: "Au local de Blois" },
    { value: "lycees-ad2e", name: "Lycées AD2E" },
    { value: "zupdefoot", name: "ZUPdeFOOT" },
    { value: "ase", name: "ASE" },
    { value: "Suzali", name: "Suzali" },
    { value: "1jeune1mentor", name: "1jeune1mentor" },
  ];
};

export const TutorPartnerList = (intl: any) => {
  return [
    { value: "homeclasse-convention", name: "HomeClasse Convention" },
    { value: "local-de-blois", name: "Au local de Blois" },
    { value: "lycees-ad2e", name: "Lycées AD2E" },
    { value: "zupdefoot", name: "ZUPdeFOOT" },
    { value: "ase", name: "ASE" },
    { value: "Suzali", name: "Suzali" },
    { value: "1jeune1mentor", name: "1jeune1mentor" },
  ];
};

export const ActiveInactiveList = (intl: any) => {
  return [
    { value: "active", name: intl.formatMessage({ id: "active" }) },
    { value: "deactivated", name: intl.formatMessage({ id: "deactivated" }) },
  ];
};

export const VscStatuses = (intl: any) => {
  return [
    { value: "active", name: intl.formatMessage({ id: "active" }) },
    { value: "deactivated", name: intl.formatMessage({ id: "deactivated" }) },
    {
      value: "commitment-end",
      name: intl.formatMessage({ id: "commitment-end" }),
    },
    {
      value: "preemptive-interruption",
      name: intl.formatMessage({ id: "preemptive-interruption" }),
    },
  ];
};

export const StudiesList = (intl: any) => {
  return [
    {
      value: "sciences-engineering",
      name: intl.formatMessage({ id: "sciences-engineering" }),
    },
    {
      value: "social-humanities",
      name: intl.formatMessage({ id: "social-humanities" }),
    },
    { value: "arts-letters", name: intl.formatMessage({ id: "arts-letters" }) },
    {
      value: "business-management",
      name: intl.formatMessage({ id: "business-management" }),
    },
    {
      value: "health-life-sciences",
      name: intl.formatMessage({ id: "health-life-sciences" }),
    },
    {
      value: "environmental-sciences-sustainability",
      name: intl.formatMessage({ id: "environmental-sciences-sustainability" }),
    },
    { value: "ict", name: intl.formatMessage({ id: "ict" }) },
    {
      value: "computer-science",
      name: intl.formatMessage({ id: "computer-science" }),
    },
    {
      value: "education-training",
      name: intl.formatMessage({ id: "education-training" }),
    },
    {
      value: "communication-journalism",
      name: intl.formatMessage({ id: "communication-journalism" }),
    },
    { value: "other", name: intl.formatMessage({ id: "other" }) },
  ];
};

export const UserRolesList = (intl: any) => {
  return [
    { value: "tutor", name: intl.formatMessage({ id: "tutor" }) },
    { value: "parent", name: intl.formatMessage({ id: "parent" }) },
    { value: "student", name: intl.formatMessage({ id: "student" }) },
    {
      value: "super_administrator",
      name: intl.formatMessage({ id: "super_administrator" }),
    },
    { value: "manager", name: intl.formatMessage({ id: "manager" }) },
    { value: "coordinator", name: intl.formatMessage({ id: "coordinator" }) },
    { value: "vsc", name: intl.formatMessage({ id: "vsc" }) },
  ];
};

export const RecurrenceList = (intl: any) => {
  return [
    { value: "weekly", name: intl.formatMessage({ id: "weekly" }) },
    { value: "bi-monthly", name: intl.formatMessage({ id: "bi-monthly" }) },
    { value: "one-time", name: intl.formatMessage({ id: "one-time" }) },
  ];
};

export const ReportsStatusList = (intl: any) => {
  return [
    {
      value: ReportsStatus.toBeEntered,
      name: intl.formatMessage({ id: "to-be-entered" }),
    },
    {
      value: ReportsStatus.entered,
      name: intl.formatMessage({ id: "entered" }),
    },
    {
      value: ReportsStatus.confirmed,
      name: intl.formatMessage({ id: "confirmed" }),
    },
  ];
};

export const ReportsTypesList = (intl: any) => {
  return [
    {
      value: ReportsTypes.crReport,
      name: intl.formatMessage({ id: "cr-report" }),
    },
    {
      value: ReportsTypes.scReport,
      name: intl.formatMessage({ id: "sc-report" }),
    },
  ];
};

export const StatusOfEstablishments = (intl: any) => {
  return [
    {
      value: StatusOfEstablishment.ACTIVE,
      name: intl.formatMessage({ id: "active" }),
    },
    {
      value: StatusOfEstablishment.PROSPECT,
      name: intl.formatMessage({ id: "prospect" }),
    },
    {
      value: StatusOfEstablishment.INACTIVE,
      name: intl.formatMessage({ id: "inactive" }),
    },
  ];
};

export const HolidayStatusesList = (intl: any) => {
  return [
    { value: "allowed", name: intl.formatMessage({ id: "allowed" }) },
    { value: "not-allowed", name: intl.formatMessage({ id: "not-allowed" }) },
  ];
};

export const ListOfSchools = () => [
  {
    department: "41",
    city: "BLOIS",
    typeOfSchool: "Collège",
    nameOfSchool: "Bégon",
    valueOfSchool: "begon",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "BLOIS",
    typeOfSchool: "Collège",
    nameOfSchool: "Rabelais",
    valueOfSchool: "rabelais",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "BLOIS",
    typeOfSchool: "Collège",
    nameOfSchool: "Augustin",
    valueOfSchool: "augustin",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "BLOIS",
    typeOfSchool: "Collège",
    nameOfSchool: "Les Provinces",
    valueOfSchool: "les-provinces",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "Romorantin",
    typeOfSchool: "Collège",
    nameOfSchool: "Léonard de Vinci",
    valueOfSchool: "leonard-de-vinci",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "ONZAIN",
    typeOfSchool: "Collège",
    nameOfSchool: "Joseph Crocheton",
    valueOfSchool: "joseph-crocheton",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "BLOIS",
    typeOfSchool: "Collège",
    nameOfSchool: "Lycee Camille Claudel",
    valueOfSchool: "camille-claudel",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "BLOIS",
    typeOfSchool: "Collège",
    nameOfSchool: "Lycée Augustin",
    valueOfSchool: "augustin",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "69",
    city: "Lyon 3",
    typeOfSchool: "Collège",
    nameOfSchool: "Raoul Dufy",
    valueOfSchool: "raoul-dufy",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Lyon 3",
    typeOfSchool: "Collège",
    nameOfSchool: "Professeur Dargent",
    valueOfSchool: "professeur-dargent",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Lyon 5",
    typeOfSchool: "Collège",
    nameOfSchool: "Charcot",
    valueOfSchool: "charcot",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Lyon 8",
    typeOfSchool: "Collège",
    nameOfSchool: "Mermoz",
    valueOfSchool: "mermoz",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Lyon 8",
    typeOfSchool: "Collège",
    nameOfSchool: "Victor Grignard",
    valueOfSchool: "victor-grignard",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Vénissieux",
    typeOfSchool: "Collège",
    nameOfSchool: "Paul Eluard",
    valueOfSchool: "paul-eluard",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Vénissieux",
    typeOfSchool: "Collège",
    nameOfSchool: "Michelet",
    valueOfSchool: "michelet",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Lyon 7",
    typeOfSchool: "Collège",
    nameOfSchool: "G. Rosset",
    valueOfSchool: "g-rosset",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Vaulx en Velin",
    typeOfSchool: "Collège",
    nameOfSchool: "P. Valdo",
    valueOfSchool: "p-valdo",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Vaulx en Velin",
    typeOfSchool: "Collège",
    nameOfSchool: "Ecole p. Makarenko B",
    valueOfSchool: "ecole-p-makarenko-b",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Vaulx en Velin",
    typeOfSchool: "Collège",
    nameOfSchool: "Ecole p. Anne Frank",
    valueOfSchool: "ecole-p-anne-frank",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Villeurbanne",
    typeOfSchool: "Collège",
    nameOfSchool: "Simone Lagrange",
    valueOfSchool: "simone-lagrange",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Villeurbanne",
    typeOfSchool: "Collège",
    nameOfSchool: "Les Iris",
    valueOfSchool: "les-iris",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Villeurbanne",
    typeOfSchool: "Collège",
    nameOfSchool: "Lamartine",
    valueOfSchool: "lamartine",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Villeurbanne",
    typeOfSchool: "Collège",
    nameOfSchool: "Jean Jaurès",
    valueOfSchool: "jean-jaures",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Lyon 7",
    typeOfSchool: "Collège",
    nameOfSchool: "Gisèle Halimi",
    valueOfSchool: "gisele-halimi",
    coordinatorInCharge: "Marie-Noelle",
  },
  {
    department: "69",
    city: "Lyon 9",
    typeOfSchool: "Collège",
    nameOfSchool: "Jean de Verrazane",
    valueOfSchool: "jean-de-verrazane",
    coordinatorInCharge: "Marie-Noelle",
  },
  {
    department: "75",
    city: "Paris 10",
    typeOfSchool: "Collège",
    nameOfSchool: "Françoise Seligmann",
    valueOfSchool: "francoise-seligmann",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 12",
    typeOfSchool: "Collège",
    nameOfSchool: "Paul Verlaine",
    valueOfSchool: "paul-verlaine",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 13",
    typeOfSchool: "Collège",
    nameOfSchool: "Evariste Galois",
    valueOfSchool: "evariste-galois",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 13",
    typeOfSchool: "Collège",
    nameOfSchool: "Gustave Flaubert",
    valueOfSchool: "gustave-flaubert",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 13",
    typeOfSchool: "Collège",
    nameOfSchool: "Thomas Mann",
    valueOfSchool: "thomas-mann",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 14",
    typeOfSchool: "Collège",
    nameOfSchool: "François Villon",
    valueOfSchool: "francois-villon",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 17",
    typeOfSchool: "Collège",
    nameOfSchool: "Boris Vian",
    valueOfSchool: "boris-vian",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 18",
    typeOfSchool: "Collège",
    nameOfSchool: "Aimé Césaire",
    valueOfSchool: "aime-cesaire",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 18",
    typeOfSchool: "Collège",
    nameOfSchool: "Daniel Mayer",
    valueOfSchool: "daniel-mayer",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 18",
    typeOfSchool: "Collège",
    nameOfSchool: "Hector Berlioz",
    valueOfSchool: "hector-berlioz",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 20",
    typeOfSchool: "Collège",
    nameOfSchool: "Colette Besson",
    valueOfSchool: "colette-besson",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "78",
    city: "Achères",
    typeOfSchool: "Collège",
    nameOfSchool: "Camille du Gast",
    valueOfSchool: "camille-du-gast",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Chanteloup les Vignes",
    typeOfSchool: "Collège",
    nameOfSchool: "Magellan",
    valueOfSchool: "magellan",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Chanteloup les Vignes",
    typeOfSchool: "Collège",
    nameOfSchool: "Cassin",
    valueOfSchool: "cassin",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Elancourt",
    typeOfSchool: "Collège",
    nameOfSchool: "de l'Agiot",
    valueOfSchool: "de-l-agiot",
    coordinatorInCharge: "Sylvie",
  },
  {
    department: "78",
    city: "Houilles",
    typeOfSchool: "Collège",
    nameOfSchool: "Guy de Maupassant",
    valueOfSchool: "guy-de-maupassant",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "78",
    city: "Les Mureaux",
    typeOfSchool: "Collège",
    nameOfSchool: "Jules Verne",
    valueOfSchool: "jules-verne",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Les Mureaux",
    typeOfSchool: "Collège",
    nameOfSchool: "Paul Verlaine",
    valueOfSchool: "paul-verlaine",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Mantes la Ville",
    typeOfSchool: "Collège",
    nameOfSchool: "Les Plaisances",
    valueOfSchool: "les-plaisances",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Montigny le Bretonneux",
    typeOfSchool: "Collège",
    nameOfSchool: "Alberto Giacometti",
    valueOfSchool: "alberto-giacometti",
    coordinatorInCharge: "Sylvie",
  },
  {
    department: "78",
    city: "Conflans Sainte Honorine",
    typeOfSchool: "Collège",
    nameOfSchool: "Les Hautes Rayes",
    valueOfSchool: "les-hautes-rayes",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Sartrouville",
    typeOfSchool: "Collège",
    nameOfSchool: "Louis Paulhan",
    valueOfSchool: "louis-paulhan",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "78",
    city: "Versailles",
    typeOfSchool: "Collège",
    nameOfSchool: "JP Rameau",
    valueOfSchool: "jp-rameau",
    coordinatorInCharge: "Sylvie",
  },
  {
    department: "91",
    city: "Etampes",
    typeOfSchool: "Collège",
    nameOfSchool: "de Guinette",
    valueOfSchool: "de-guinette",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Les Ulis",
    typeOfSchool: "Collège",
    nameOfSchool: "Mondétour",
    valueOfSchool: "mondétour",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Les Ulis",
    typeOfSchool: "Collège",
    nameOfSchool: "Aimé Césaire",
    valueOfSchool: "aime-cesaire",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Longjumeau",
    typeOfSchool: "Collège",
    nameOfSchool: "Louis Pasteur",
    valueOfSchool: "louis-pasteur",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Massy",
    typeOfSchool: "Collège",
    nameOfSchool: "Blaise Pascal",
    valueOfSchool: "blaise-pascal",
    coordinatorInCharge: "Aude",
  },
  {
    department: "91",
    city: "Massy",
    typeOfSchool: "Collège",
    nameOfSchool: "Diderot",
    valueOfSchool: "diderot",
    coordinatorInCharge: "Aude",
  },
  {
    department: "91",
    city: "Massy",
    typeOfSchool: "Collège",
    nameOfSchool: "Gérard Philipe",
    valueOfSchool: "gerard-philipe",
    coordinatorInCharge: "Aude",
  },
  {
    department: "91",
    city: "Morangis",
    typeOfSchool: "Collège",
    nameOfSchool: "Michel Vignaud",
    valueOfSchool: "michel-vignaud",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Palaiseau",
    typeOfSchool: "Collège",
    nameOfSchool: "Joseph Bara",
    valueOfSchool: "joseph-bara",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Palaiseau",
    typeOfSchool: "Collège",
    nameOfSchool: "César Franck",
    valueOfSchool: "cesar-franck",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Saint Michel sur Orge",
    typeOfSchool: "Collège",
    nameOfSchool: "Nicolas Boileau",
    valueOfSchool: "nicolas-boileau",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Ris-Orangis",
    typeOfSchool: "Collège",
    nameOfSchool: "Albert Camus",
    valueOfSchool: "albert-camus",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "92",
    city: "Antony",
    typeOfSchool: "Collège",
    nameOfSchool: "Anne Frank",
    valueOfSchool: "anne-frank",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Asnières sur Seine",
    typeOfSchool: "Collège",
    nameOfSchool: "André Malraux",
    valueOfSchool: "andre-malraux",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "92",
    city: "Bagneux",
    typeOfSchool: "Collège",
    nameOfSchool: "Henri Barbusse",
    valueOfSchool: "henri-barbusse",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Bagneux",
    typeOfSchool: "Collège",
    nameOfSchool: "Joliot Curie",
    valueOfSchool: "joliot-curie",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Bagneux",
    typeOfSchool: "Collège",
    nameOfSchool: "Romain Rolland",
    valueOfSchool: "romain-rolland",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Bourg la Reine",
    typeOfSchool: "Collège",
    nameOfSchool: "Evariste Galois",
    valueOfSchool: "evariste-galois",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Chatenay Malabry",
    typeOfSchool: "Collège",
    nameOfSchool: "Léonard de Vinci",
    valueOfSchool: "leonard-de-vinci",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Chatenay Malabry",
    typeOfSchool: "Collège",
    nameOfSchool: "Thomas Masaryk",
    valueOfSchool: "thomas-masaryk",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Clichy",
    typeOfSchool: "Collège",
    nameOfSchool: "Jean Jaurès",
    valueOfSchool: "jean-jaures",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "92",
    city: "Colombes",
    typeOfSchool: "Collège",
    nameOfSchool: "Jean-Baptiste Clément",
    valueOfSchool: "jean-baptiste-clement",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "92",
    city: "Colombes",
    typeOfSchool: "Collège",
    nameOfSchool: "Marguerite Duras",
    valueOfSchool: "marguerite-duras",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "92",
    city: "Nanterre",
    typeOfSchool: "Collège",
    nameOfSchool: "André Doucet",
    valueOfSchool: "andre-doucet",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "92",
    city: "Nanterre",
    typeOfSchool: "Collège",
    nameOfSchool: "Paul Eluard",
    valueOfSchool: "paul-eluard",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "92",
    city: "Villeneuve la Garenne",
    typeOfSchool: "Collège",
    nameOfSchool: "Pompidou",
    valueOfSchool: "pompidou",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "95",
    city: "Argenteuil",
    typeOfSchool: "Collège",
    nameOfSchool: "Ariane",
    valueOfSchool: "ariane",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "95",
    city: "Argenteuil",
    typeOfSchool: "Collège",
    nameOfSchool: "Rousseau",
    valueOfSchool: "rousseau",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "95",
    city: "Bezons",
    typeOfSchool: "Collège",
    nameOfSchool: "Henri Wallon",
    valueOfSchool: "henri-wallon",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "95",
    city: "Bezons",
    typeOfSchool: "Collège",
    nameOfSchool: "Gabriel Peri",
    valueOfSchool: "gabriel-peri",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "95",
    city: "Cergy",
    typeOfSchool: "Collège",
    nameOfSchool: "Les Explorateurs",
    valueOfSchool: "les-explorateurs",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "95",
    city: "Cergy",
    typeOfSchool: "Collège",
    nameOfSchool: "Les Touleuses",
    valueOfSchool: "les-touleuses",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "95",
    city: "Ermont",
    typeOfSchool: "Collège",
    nameOfSchool: "Jules Ferry",
    valueOfSchool: "jules-ferry",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "95",
    city: "Garges-lès-Gonèsse",
    typeOfSchool: "Collège",
    nameOfSchool: "Paul Eluard",
    valueOfSchool: "paul-eluard",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "95",
    city: "Pontoise",
    typeOfSchool: "Collège",
    nameOfSchool: "Simone Veil",
    valueOfSchool: "simone-veil",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "95",
    city: "St Ouen L'Aumône",
    typeOfSchool: "Collège",
    nameOfSchool: "Marcel Pagnol",
    valueOfSchool: "marcel-pagnol",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "75",
    city: "PARIS 10",
    typeOfSchool: "Lycée",
    nameOfSchool: "Marie-Laurencin",
    valueOfSchool: "marie-laurencin",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "75",
    city: "PARIS 20",
    typeOfSchool: "Lycée",
    nameOfSchool: "Charles de Gaulle",
    valueOfSchool: "charles-de-gaulle",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "75",
    city: "PARIS 19",
    typeOfSchool: "Lycée",
    nameOfSchool: "Diderot",
    valueOfSchool: "diderot",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "75",
    city: "PARIS 9",
    typeOfSchool: "Lycée",
    nameOfSchool: "Jacques Decour",
    valueOfSchool: "jacques-decour",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "75",
    city: "PARIS 18",
    typeOfSchool: "Lycée",
    nameOfSchool: "Camille Jenatzy",
    valueOfSchool: "camille-jenatzy",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "93",
    city: "SAINT-DENIS",
    typeOfSchool: "Lycée",
    nameOfSchool: "Frédéric Bartholdi",
    valueOfSchool: "frederic-bartholdi",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "93",
    city: "BONDY",
    typeOfSchool: "Lycée",
    nameOfSchool: "Madeleine Vionnet",
    valueOfSchool: "madeleine-vionnet",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "93",
    city: "BONDY",
    typeOfSchool: "Lycée",
    nameOfSchool: "Jean Renoir",
    valueOfSchool: "jean-renoir",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "93",
    city: "FONTENAY SOUS BOIS",
    typeOfSchool: "Lycée",
    nameOfSchool: "Jules Michelet",
    valueOfSchool: "jules-michelet",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "93",
    city: "ROSNY SOUS BOIS",
    typeOfSchool: "Lycée",
    nameOfSchool: "Charles de Gaulle",
    valueOfSchool: "charles-de-gaulle",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "75",
    city: "PARIS 14",
    typeOfSchool: "Lycée",
    nameOfSchool: "Raspail",
    valueOfSchool: "raspail",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: null,
    city: null,
    typeOfSchool: null,
    nameOfSchool: "Other",
    valueOfSchool: "other",
    coordinatorInCharge: null,
  },
];

export const PlaceOrRooms: { id: string; name: string }[] = [
  { id: "bce456Ci", name: "Domicile" },
  { id: "qlc116kv", name: "Etablissement scolaire" },
  { id: "rcl232xf", name: "Club de sport" },
  { id: "ehd523qa", name: "Autres" },
];

export const departmentsList = [
  { departmentId: "01", departmentName: "Ain (01)", departmentCode: "01" },
  { departmentId: "02", departmentName: "Aisne (02)", departmentCode: "02" },
  { departmentId: "03", departmentName: "Allier (03)", departmentCode: "03" },
  {
    departmentId: "04",
    departmentName: "Alpes-de-Haute-Provence (04)",
    departmentCode: "04",
  },
  {
    departmentId: "05",
    departmentName: "Hautes-Alpes (05)",
    departmentCode: "05",
  },
  {
    departmentId: "06",
    departmentName: "Alpes-Maritimes (06)",
    departmentCode: "06",
  },
  { departmentId: "07", departmentName: "Ardèche (07)", departmentCode: "07" },
  { departmentId: "08", departmentName: "Ardennes (08)", departmentCode: "08" },
  { departmentId: "09", departmentName: "Ariège (09)", departmentCode: "09" },
  { departmentId: "10", departmentName: "Aube (10)", departmentCode: "10" },
  { departmentId: "11", departmentName: "Aude (11)", departmentCode: "11" },
  { departmentId: "12", departmentName: "Aveyron (12)", departmentCode: "12" },
  {
    departmentId: "13",
    departmentName: "Bouches-du-Rhône (13)",
    departmentCode: "13",
  },
  { departmentId: "14", departmentName: "Calvados (14)", departmentCode: "14" },
  { departmentId: "15", departmentName: "Cantal (15)", departmentCode: "15" },
  { departmentId: "16", departmentName: "Charente (16)", departmentCode: "16" },
  {
    departmentId: "17",
    departmentName: "Charente-Maritime (17)",
    departmentCode: "17",
  },
  { departmentId: "18", departmentName: "Cher (18)", departmentCode: "18" },
  { departmentId: "19", departmentName: "Corrèze (19)", departmentCode: "19" },
  {
    departmentId: "2A",
    departmentName: "Corse-du-Sud (2A)",
    departmentCode: "2A",
  },
  {
    departmentId: "2B",
    departmentName: "Haute-Corse (2B)",
    departmentCode: "2B",
  },
  {
    departmentId: "21",
    departmentName: "Côte-d'Or (21)",
    departmentCode: "21",
  },
  {
    departmentId: "22",
    departmentName: "Côtes-d'Armor (22)",
    departmentCode: "22",
  },
  { departmentId: "23", departmentName: "Creuse (23)", departmentCode: "23" },
  { departmentId: "24", departmentName: "Dordogne (24)", departmentCode: "24" },
  { departmentId: "25", departmentName: "Doubs (25)", departmentCode: "25" },
  { departmentId: "26", departmentName: "Drôme (26)", departmentCode: "26" },
  { departmentId: "27", departmentName: "Eure (27)", departmentCode: "27" },
  {
    departmentId: "28",
    departmentName: "Eure-et-Loir (28)",
    departmentCode: "28",
  },
  {
    departmentId: "29",
    departmentName: "Finistère (29)",
    departmentCode: "29",
  },
  { departmentId: "30", departmentName: "Gard (30)", departmentCode: "30" },
  {
    departmentId: "31",
    departmentName: "Haute-Garonne (31)",
    departmentCode: "31",
  },
  { departmentId: "32", departmentName: "Gers (32)", departmentCode: "32" },
  { departmentId: "33", departmentName: "Gironde (33)", departmentCode: "33" },
  { departmentId: "34", departmentName: "Hérault (34)", departmentCode: "34" },
  {
    departmentId: "35",
    departmentName: "Ille-et-Vilaine (35)",
    departmentCode: "35",
  },
  { departmentId: "36", departmentName: "Indre (36)", departmentCode: "36" },
  {
    departmentId: "37",
    departmentName: "Indre-et-Loire (37)",
    departmentCode: "37",
  },
  { departmentId: "38", departmentName: "Isère (38)", departmentCode: "38" },
  { departmentId: "39", departmentName: "Jura (39)", departmentCode: "39" },
  { departmentId: "40", departmentName: "Landes (40)", departmentCode: "40" },
  {
    departmentId: "41",
    departmentName: "Loir-et-Cher (41)",
    departmentCode: "41",
  },
  { departmentId: "42", departmentName: "Loire (42)", departmentCode: "42" },
  {
    departmentId: "43",
    departmentName: "Haute-Loire (43)",
    departmentCode: "43",
  },
  {
    departmentId: "44",
    departmentName: "Loire-Atlantique (44)",
    departmentCode: "44",
  },
  { departmentId: "45", departmentName: "Loiret (45)", departmentCode: "45" },
  { departmentId: "46", departmentName: "Lot (46)", departmentCode: "46" },
  {
    departmentId: "47",
    departmentName: "Lot-et-Garonne (47)",
    departmentCode: "47",
  },
  { departmentId: "48", departmentName: "Lozère (48)", departmentCode: "48" },
  {
    departmentId: "49",
    departmentName: "Maine-et-Loire (49)",
    departmentCode: "49",
  },
  { departmentId: "50", departmentName: "Manche (50)", departmentCode: "50" },
  { departmentId: "51", departmentName: "Marne (51)", departmentCode: "51" },
  {
    departmentId: "52",
    departmentName: "Haute-Marne (52)",
    departmentCode: "52",
  },
  { departmentId: "53", departmentName: "Mayenne (53)", departmentCode: "53" },
  {
    departmentId: "54",
    departmentName: "Meurthe-et-Moselle (54)",
    departmentCode: "54",
  },
  { departmentId: "55", departmentName: "Meuse (55)", departmentCode: "55" },
  { departmentId: "56", departmentName: "Morbihan (56)", departmentCode: "56" },
  { departmentId: "57", departmentName: "Moselle (57)", departmentCode: "57" },
  { departmentId: "58", departmentName: "Nièvre (58)", departmentCode: "58" },
  { departmentId: "59", departmentName: "Nord (59)", departmentCode: "59" },
  { departmentId: "60", departmentName: "Oise (60)", departmentCode: "60" },
  { departmentId: "61", departmentName: "Orne (61)", departmentCode: "61" },
  {
    departmentId: "62",
    departmentName: "Pas-de-Calais (62)",
    departmentCode: "62",
  },
  {
    departmentId: "63",
    departmentName: "Puy-de-Dôme (63)",
    departmentCode: "63",
  },
  {
    departmentId: "64",
    departmentName: "Pyrénées-Atlantiques (64)",
    departmentCode: "64",
  },
  {
    departmentId: "65",
    departmentName: "Hautes-Pyrénées (65)",
    departmentCode: "65",
  },
  {
    departmentId: "66",
    departmentName: "Pyrénées-Orientales (66)",
    departmentCode: "66",
  },
  { departmentId: "67", departmentName: "Bas-Rhin (67)", departmentCode: "67" },
  {
    departmentId: "68",
    departmentName: "Haut-Rhin (68)",
    departmentCode: "68",
  },
  { departmentId: "69", departmentName: "Rhône (69)", departmentCode: "69" },
  {
    departmentId: "70",
    departmentName: "Haute-Saône (70)",
    departmentCode: "70",
  },
  {
    departmentId: "71",
    departmentName: "Saône-et-Loire (71)",
    departmentCode: "71",
  },
  { departmentId: "72", departmentName: "Sarthe (72)", departmentCode: "72" },
  { departmentId: "73", departmentName: "Savoie (73)", departmentCode: "73" },
  {
    departmentId: "74",
    departmentName: "Haute-Savoie (74)",
    departmentCode: "74",
  },
  { departmentId: "75", departmentName: "Paris (75)", departmentCode: "75" },
  {
    departmentId: "76",
    departmentName: "Seine-Maritime (76)",
    departmentCode: "76",
  },
  {
    departmentId: "77",
    departmentName: "Seine-et-Marne (77)",
    departmentCode: "77",
  },
  { departmentId: "78", departmentName: "Yvelines (78)", departmentCode: "78" },
  {
    departmentId: "79",
    departmentName: "Deux-Sèvres (79)",
    departmentCode: "79",
  },
  { departmentId: "80", departmentName: "Somme (80)", departmentCode: "80" },
  { departmentId: "81", departmentName: "Tarn (81)", departmentCode: "81" },
  {
    departmentId: "82",
    departmentName: "Tarn-et-Garonne (82)",
    departmentCode: "82",
  },
  { departmentId: "83", departmentName: "Var (83)", departmentCode: "83" },
  { departmentId: "84", departmentName: "Vaucluse (84)", departmentCode: "84" },
  { departmentId: "85", departmentName: "Vendée (85)", departmentCode: "85" },
  { departmentId: "86", departmentName: "Vienne (86)", departmentCode: "86" },
  {
    departmentId: "87",
    departmentName: "Haute-Vienne (87)",
    departmentCode: "87",
  },
  { departmentId: "88", departmentName: "Vosges (88)", departmentCode: "88" },
  { departmentId: "89", departmentName: "Yonne (89)", departmentCode: "89" },
  {
    departmentId: "90",
    departmentName: "Territoire de Belfort (90)",
    departmentCode: "90",
  },
  { departmentId: "91", departmentName: "Essonne (91)", departmentCode: "91" },
  {
    departmentId: "92",
    departmentName: "Hauts-de-Seine (92)",
    departmentCode: "92",
  },
  {
    departmentId: "93",
    departmentName: "Seine-Saint-Denis (93)",
    departmentCode: "93",
  },
  {
    departmentId: "94",
    departmentName: "Val-de-Marne (94)",
    departmentCode: "94",
  },
  {
    departmentId: "95",
    departmentName: "Val-d'Oise (95)",
    departmentCode: "95",
  },
  {
    departmentId: "971",
    departmentName: "Guadeloupe (971)",
    departmentCode: "971",
  },
  {
    departmentId: "972",
    departmentName: "Martinique (972)",
    departmentCode: "972",
  },
  {
    departmentId: "973",
    departmentName: "Guyane (973)",
    departmentCode: "973",
  },
  {
    departmentId: "974",
    departmentName: "La Réunion (974)",
    departmentCode: "974",
  },
  {
    departmentId: "976",
    departmentName: "Mayotte (976)",
    departmentCode: "976",
  },
];
